<template>
 <PageHeading :title="$t('collectionsList')" :has-action-button="false" :has-meta-data="false" />
 <div v-if="files.length > 0">
  <div class="flex flex-col">
   <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
    <div class="align-middle inline-block min-w-full sm:px-6 lg:px-10">
     <div>
      <ul role="list" class="mt-3 grid grid-cols-1 gap-5">
       <li v-for="(file, idx) in files" :key="idx" class="col-span-1 flex rounded-md shadow-sm">
        <div
         class="flex flex-1 items-center justify-between truncate rounded-md border border-gray-200 bg-white"
        >
         <div class="flex-1 truncate px-4 py-2 text-sm">
          <a
           @click="
            adminToken
             ? goToDetail(file.customer_file_id, file.id)
             : file.status !== 'submitted' && file.status !== 'ready' && file.status !== 'closed'
             ? goToDetail(file.customer_file_id, file.id)
             : 'javascript:void(0)'
           "
           :class="[
            adminToken
             ? 'cursor-pointer hover:text-gray-600'
             : file.status !== 'submitted' && file.status !== 'ready' && file.status !== 'closed'
             ? 'cursor-pointer hover:text-gray-600'
             : '',
            'font-medium text-gray-900 mr-2',
           ]"
           :title="
            file.status !== 'submitted' && file.status !== 'ready' && file.status !== 'closed'
             ? $t('accessToCollect', { name: file.customer_file.name })
             : ''
           "
          >
           {{ file.customer_file.name ? file.customer_file.name : file.customer_file_id }}
          </a>
          <span
           :class="[
            file.status === 'open'
             ? 'bg-blue-100 text-blue-800'
             : file.status === 'submitted'
             ? 'bg-green-100 text-green-800'
             : 'bg-red-100 text-red-800',
            'px-2 inline-flex text-xs font-semibold rounded-full',
           ]"
          >
           {{ $t(getStatus(file.status)) }}
          </span>
          <div>
           <span
            v-if="file.locked_id && file.locked_id !== locked_id"
            class="font-medium flex text-gray-700 mr-1"
           >
            <font-awesome-icon
             icon="exclamation-triangle"
             class="h-4 w-4 mr-2 mt-px text-yellow-300 fa-fade"
             aria-hidden="true"
            />
            {{ $t("collectLockedDescription") }}
           </span>
           <span v-else-if="file.status === 'submitted'" class="font-medium text-gray-700 mr-1">
            {{ $t("collectSubmittedAt", { date: $d(file.updated_at, "longNumb") }) }}
           </span>
           <p v-else class="text-gray-500">
            {{ $t("collectCompletedAt", { numb: file.progress }) }}
           </p>
          </div>
         </div>
         <div class="flex-shrink-0 pr-2">
          <button
           type="button"
           @click="getData()"
           @mouseenter="iconHovers[idx] = true"
           @mouseleave="iconHovers[idx] = false"
           class="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-400 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
           <font-awesome-icon
            icon="arrows-rotate"
            class="flex-shrink-0 h-4 w-4"
            :class="{ 'fa-spin': iconHovers[idx] }"
           />
          </button>
         </div>
        </div>
       </li>
      </ul>
     </div>
    </div>
   </div>
  </div>
 </div>
 <div v-else>
  <EmptyState
   :title="$t('emptyStateTitle', { title: $t('onlineCollect') })"
   :subtitle="$t('emptyStateSubtitle', { title: $t('onlineCollect') })"
  />
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import ProgressBar from "../../components/ProgressBar.vue";

import SearchFilter from "../../components/SearchFilter.vue";
import FilesList from "../../components/Collect/FilesList.vue";
import PageHeading from "../../components/PageHeading.vue";
import EmptyState from "../../components/EmptyState.vue";

const adminToken = localStorage.getItem("adminToken");
const account =
 localStorage.getItem("account") === ""
  ? this.$store.state.user.activeAccount
  : localStorage.getItem("account");
const locked_id = localStorage.getItem("locked_id");

export default {
 props: ["search"],
 components: {
  EmptyState,
  FilesList,
  ProgressBar,
  SearchFilter,
  PageHeading,
 },
 data() {
  return {
   adminToken,
   account,
   activeBanner: false,
   currentSort: "id",
   currentSortDir: "asc",
   data: [],
   files: [],
   isSuccess: true,
   isLoading: false,
   isHover: false,
   iconHovers: [],
   key: false,
   locked_id,
   successMsg: "",
   progressArray: [],
  };
 },
 created() {
  this.iconHovers = this.files.map(() => false);
 },
 methods: {
  async getData() {
   this.$emit("setLoading", true);

   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/informationCollections?customerAccount=${
      this.account
     }`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    this.data = res.data;
    this.files = res.data.data;
    this.$emit("setLoading", false);

    if (this.files.length > 0) this.updateProgressValues();
   } catch (error) {
    console.error(error.response);
    this.$emit("setLoading", false);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },
  async getProgress(id) {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/informationCollections/${id}?customerAccount=${
      this.account
     }`
    );
    let data = res.data.content;
    let completedCount = 0;
    let completedPercentage = 0;
    if (res.data.content) {
     let objData = JSON.parse(data);
     let keys = Object.keys(objData);
     keys.forEach((res) => {
      (res !== "callQueue" || res !== "studio" || res !== "svi") &&
      (objData[res].length > 0 || Object.keys(objData[res]).length > 0)
       ? (completedCount = completedCount + 1)
       : (completedCount = completedCount);
     });
    }

    completedPercentage = (completedCount / 7) * 100;
    if (completedPercentage > 100) completedPercentage = 100;

    return completedPercentage.toFixed(0);
   } catch (error) {
    console.error(error);

    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", this.errorStatusText);
   }
  },
  async updateProgressValues() {
   for (let i = 0; i < this.files.length; i++) {
    this.files[i].progress = await this.getProgress(this.files[i].id);
   }
   this.$emit("setLoading", false);
  },
  getStatus(status) {
   return status === "open"
    ? "collectOpen"
    : status === "submitted"
    ? "submitted"
    : status === "active"
    ? "active"
    : status === "ready"
    ? "readyToBeDeployed"
    : status === "complete"
    ? "completed"
    : "unknown";
  },
  goToDetail(customer_file_id, id) {
   let routeData = undefined;
   if (id) {
    routeData = this.$router.resolve({
     path: `/customer-files/collect/${customer_file_id}/${id}/0`,
    });
   } else {
    routeData = this.$router.resolve({
     path: `/customer-files/collect/${customer_file_id}/new/0`,
    });
   }
   this.$router.push(routeData.path);
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sorted() {
   return this.files.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
    if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    return 0;
   });
  },
  filteredInvoices() {
   return this.sorted().filter((res) => {
    return res.name
     ? res.name.toLowerCase().includes(this.search.toLowerCase())
     : res || res.id == this.search;
   });
  },
 },

 mounted() {
  this.getData();
 },
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>
