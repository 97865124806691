<template>
  <div class="bg-white">
    <div class="px-4 py-5 border-b border-gray-200 sm:px-6 bg-gray-50">
      <div
        class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
      >
        <div class="ml-4 mt-2">
          <h3 class="text-lg leading-6 font-semibold text-gray-900">
            {{ $t("files") }} {{ $t("account") }} {{ account }}
          </h3>
        </div>
        <!-- <div class="ml-4 mt-2 flex-shrink-0">
          <button
            type="button"
            class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__header--button"
          >
            {{ $t("newCollection") }}
          </button>
        </div> -->
      </div>
    </div>
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="file in datas" :key="file.id">
        <a
          @click="goToDetail(file.id)"
          class="block hover:bg-gray-50 cursor-pointer"
        >
          <div class="flex items-center px-4 py-4">
            <div class="min-w-0 flex-1 flex items-center">
              <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <p class="text-sm font-medium truncate">
                    {{ file.name ? file.name : file.id }}
                  </p>
                  <!-- <p class="mt-2 flex items-center text-sm text-gray-500">
                    <font-awesome-icon
                      icon="user"
                      class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                    />
                    <span class="truncate">{{
                      file.salesRepInfos ? file.salesRepInfos.name : "none"
                    }}</span>
                  </p> -->
                </div>
                <div class="hidden md:block">
                  <div>
                    <p class="text-sm text-gray-900">
                      {{ $t("createdAt") }}
                      {{ " " }}
                      <time :datetime="file.created_at" class="capitalize">
                        {{ $d(file.created_at, "longNumb") }}
                      </time>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { UserCircleIcon } from "@heroicons/vue/solid";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: ["datas", "account"],
  components: {
    UserCircleIcon,
    Loading,
  },
  data() {
    return {
      fullPage: false,
      userInfos: {},
    };
  },
  methods: {
    goToDetail(id) {
      let routeData = this.$router.resolve({
        path: `/customer-files/collectList/${id}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {},
  computed: {},
};
</script>

<style></style>
